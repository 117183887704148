import React from 'react';

class LogoBar extends React.Component {
  render() {
    return (
      <section className="logobar">
        <img className="logobar-logo" src="../images/logo.png" />
      </section>
    );
  }
}

export default LogoBar;
