import React from 'react';

class SearchBox extends React.Component {
  render() {
    return (
      <section className="searchbox-box">
        <div className="searchbox-container">
            <span className="searchbox-icon"></span>
            <input type="search" id="search" />
        </div>
      </section>
    );
  }
}


export default SearchBox;
