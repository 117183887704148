import ChatSocket from '../components/ChatScreen/ChatSocket';
import { connect } from 'react-redux';
import { chatActions } from '../redux/actions';

function mapStateToProps(state, ownProps){
  return {
    user : state.getIn(['user'])
  }
}

export default connect( mapStateToProps , chatActions )(ChatSocket);
