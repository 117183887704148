import * as actions from '../CONSTANTS';
import { Map } from 'immutable';

export default (state = Map(), action) => {
  switch (action.type) {
    case actions.OPEN_MAIN_DIALOG:
      let mainDialogs = state.getIn(['main']).toObject();
      for(var dialog in mainDialogs){
        state = state.updateIn( ['main', dialog] , value => false )
      }
      return state.updateIn( ['main', action.dialog] , value => true);
    case actions.CLOSE_MAIN_DIALOG:
      return state.updateIn( ['main', action.dialog] , value => false);
    case actions.OPEN_CHAT_DIALOG:
      let chatDialogs = state.getIn(['chat']).toObject();
      for(var dialog in chatDialogs){
        state = state.updateIn( ['chat', dialog] , value => false )
      }
      return state.updateIn( ['chat', action.dialog] , value => true);
    case actions.CLOSE_CHAT_DIALOG:
      return state.updateIn( ['chat', action.dialog] , value => false);
    case actions.OPEN_LOCATION_DIALOG:
      let locationDialogs = state.getIn(['location']).toObject();
      for(var dialog in locationDialogs){
        state = state.updateIn( ['location', dialog] , value => false )
      }
      return state.updateIn( ['location', action.dialog] , value => true);
    case actions.CLOSE_LOCATION_DIALOG:
      return state.updateIn( ['location', action.dialog] , value => false);
    case actions.LOGIN_SUCCESS:
      return state.updateIn(['main','login'],value=>false);
    case actions.REGISTER_SUCCESS:
      return state.updateIn(['main','join'],value=>false);
    default:
      return state;
  }
  return newState;
}
