import ismobilejs from 'ismobilejs';

export const isMobile = ismobilejs.any;

export const isAndroid = ismobilejs.android.device;

export const isIphone = ismobilejs.apple.phone;

export const isIpad = ismobilejs.apple.tablet;

export const isTablet = ismobilejs.tablet;

export const isApple = ismobilejs.apple.device;

export default {
  isMobile: isMobile,
  isAndroid: isAndroid,
  isIphone: isIphone,
  isIpad: isIpad,
  isTablet: isTablet,
  isApple: isApple
}
