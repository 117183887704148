import {mainApi} from '../../../siteconfig.json';
import ajaxPromise from 'ajax-promise';

class PerformerUtils {
  constructor() {
    this._performerApi = mainApi;
  }

  getPerformers() {
    return ajaxPromise.get(`${this._performerApi}/api/home-performers`);
  }
}

export default new PerformerUtils();
