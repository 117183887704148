import React from 'react';
import requirejs from '../../../vendor/requirejs';
import * as VideoConstants from './common/videoConstants';

let videoElement = 'jwVideoId';

class JWPlayer extends React.Component {

  constructor(props){
    super(props);
    this.setupVideo = this.setupVideo.bind(this);
    this.removeVideo = this.removeVideo.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setupVideo(nextProps);
  }

  componentDidMount(){
    if(typeof jwplayer === 'undefined'){
      requirejs(['http://p.jwpcdn.com/6/11/jwplayer.js'], () => {
        this.setupVideo(this.props);
        }, (error) => {
          this.props.callBacks.videoFailed(VideoConstants.JWPlayer, error);
        }
      );
    }else{
      this.setupVideo(this.props);
    }
  }

  componentWillUnmount(){ this.removeVideo(); }

  setupVideo(props){
    let videoUrl = props.videoSource;
    jwplayer.key = "KgU9AOZB4PsHVA0UJlBCOgVzzQMrja+FE+gWzGV88Jc=";

    let player = jwplayer(videoElement).setup({
      file: videoUrl,
      width: "100%",
      height: '100%',
      autostart: !0,
      androidhls: !0,
      primary: "flash",
      skin: null ,
      controls: !1,
      wmode: "transparent"
    })

    this.props.callBacks.videoPlaying();

    player.onSetupError = (data) => {
      this.props.callBacks.videoFailed(VideoConstants.JWPlayer, data);
    }

    player.onError = (data) => {
      this.props.callBacks.videoFailed(VideoConstants.JWPlayer, data);
    }

    jwplayer.utils.log = (msg, obj) => {
        console.log(`jwplayer log msg: ${msg} obj: ${obj}`);

				if (msg.indexOf('No suitable') > -1
          || msg.indexOf('problem') > -1
          || msg.indexOf('No playable')) {
            this.props.callBacks.videoFailed(VideoConstants.JWPlayer, msg);
				}
			}
  }

  removeVideo(){
    if(typeof jwplayer !== 'undefined'){
      jwplayer(videoElement).remove();
    }
  }

  render(){
    return (
      <div className="jwplayer-class" id="jwVideoId"></div>
    )
  }
}

JWPlayer.propTypes = {
  callBacks: React.PropTypes.object.isRequired,
  videoSource: React.PropTypes.string.isRequired
}

export default JWPlayer;
