/*eslint-disable no-unused-vars*/
import React from 'react';
import { Route, IndexRoute, Redirect } from 'react-router';
import App from './containers/App';
import Home  from './containers/Home';
import ChatScreen from './containers/ChatScreen';
import TermsAndConditions from './containers/TermsAndConditions';
import Payments from './containers/Payments';
/*eslint-enable no-unused-vars*/

export default (
  <Route path="/" component={App}>
    <IndexRoute component={Home} />
    <Route path="Chat/:roomName" component={ChatScreen} />
    <Route path="terms-and-conditions" component={TermsAndConditions} />
    <Route path="payments" component={Payments} />
    <Redirect from="*" to="/" />
  </Route>
)
