/*eslint-disable no-unused-vars*/
import React from 'react';
import { Link } from 'react-router';
/*eslint-enable no-unused-vars*/

class FootNote extends React.Component {
  render() {
    let footnote = [
      {
        name: 'Help',
        'link': '/'
      },
      {
        name: 'Terms & Conditions',
        'link': '/terms-and-conditions'
      },
      {
        name: 'Anti-Spam Policy',
        'link': '/'
      },
      {
        name: 'Privacy Policy',
        'link': '/'
      }
    ];
    return (
      <section className="footnote-box">
        <section className="footnote-container">
          <div>© 2016 CrystalBedroom.com - All Rights Reserved!</div>
          <ul className="footnote-list">
          {
            footnote.map((option, i) => {
                return <li className="footnote-options" key={i}><Link to={option.link}>{option.name}</Link></li>;
            })
          }
          </ul>
        </section>
      </section>
    );
  }
}

export default FootNote;
