import ChatTable from '../components/ChatScreen/ChatTable';
import { connect } from 'react-redux';
import { chatActions } from '../redux/actions';

function mapStateToProps(state){
  return {
    waiting: state.getIn(['chat', 'waiting']),
    messages: state.getIn(['chat', 'messageList'])
  }
}

export default connect( mapStateToProps , chatActions )(ChatTable);
