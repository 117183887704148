import Header from '../components/Header/Header';
import { connect } from 'react-redux';
import { dialogsActions , loginActions } from '../redux/actions';

function mapStateToProps(state){
  return {
    dialogs: state.getIn(['dialogs', 'main']),
    user: state.getIn(['user'])
  }
}

export default connect( mapStateToProps , Object.assign(dialogsActions,loginActions) )(Header);
