/*eslint-disable no-unused-vars*/
import React from 'react';
import ContactBar from './ContactBar'
import FootNote from './FootNote'
import TermsBox from './TermsBox'
/*eslint-enabled no-unused-vars*/

class Footer extends React.Component {
  render() {
    return (
    <section className="footer-element">
          < ContactBar />
          < TermsBox />
          < FootNote />
    </section>
    );
  }
}

export default Footer;
