import ChatScreen from '../components/ChatScreen/ChatScreen';
import { connect } from 'react-redux';
import { dialogsActions, chatActions} from '../redux/actions';

let allActions = Object.assign({}, dialogsActions, chatActions);

function mapStateToProps(state, ownProps){
  let currentRoom = ownProps.params.roomName.toString();
  return {
    model: state.getIn(['models', 'modelList', currentRoom]),
    dialogs:state.getIn(['dialogs','location'])
  }
}

export default connect( mapStateToProps , allActions )(ChatScreen);
