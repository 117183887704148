import {mainApi} from '../../../siteconfig.json';
import Promise from 'axios';
import Storage from 'key-storage';

Promise.defaults.headers.common['Authorization'] = Storage.get('authorization');

class API {
  loginAttempt( infoObject ){
    return Promise({
      method : 'POST',
      url : `${mainApi}/api/login` ,
      data : infoObject
    });
  }
  registerAttempt( infoObject ){
    return Promise({
      method : 'POST',
      url : `${mainApi}/api/register` ,
      data : infoObject
    });
  }
}

export default new API();
