//Chat Constants
export const SEND_MESSAGE = 'chat:SEND_MESSAGE';
export const RECEIVE_MESSAGE = 'chat:RECEIVE_MESSAGE';
export const WAITING_MESSAGE = 'chat:WAITING_MESSAGE';
export const CANCEL_MESSAGE = 'chat:CANCEL_MESSAGE';
export const JOIN_ROOM = 'chat:JOIN_ROOM';
export const WAITING_JOIN = 'chat:WAITING_JOIN';
export const SOMEBODY_JOIN_ROOM = 'chat:SOMEBODY_JOIN_ROOM';
export const CONNECTION_SUCCESSFUL = 'chat:CONNECTION_SUCCESSFUL';
export const ECHO_MESSAGE = 'chat:ECHO_MESSAGE';
export const ECHO_ROOM = 'chat:ECHO_ROOM';

//Dialog/Modal Constants
export const OPEN_MAIN_DIALOG = 'dialogs:OPEN_MAIN_DIALOG';
export const CLOSE_MAIN_DIALOG = 'dialogs:CLOSE_MAIN_DIALOG';
export const OPEN_CHAT_DIALOG = 'dialogs:OPEN_CHAT_DIALOG';
export const CLOSE_CHAT_DIALOG = 'dialogs:CLOSE_CHAT_DIALOG';
export const OPEN_LOCATION_DIALOG = 'dialogs:OPEN_LOCATION_DIALOG';
export const CLOSE_LOCATION_DIALOG = 'dialogs:CLOSE_LOCATION_DIALOG';

//Header Constants
export const SEARCH_MODEL = 'header:SEARCH_MODEL';
export const FILTER_MODELS = 'header:FILTER_MODELS';

//Location/Route Constants
export const CHANGE_LOCATION = 'location:CHANGE_LOCATION';
export const CONFIRM_CHANGE = 'location:CONFIRM_CHANGE';

//Model Constants
export const LOAD_MODELS = 'models:LOAD_MODELS';
export const WAIT_MODELS = 'models:WAIT_MODELS';
export const CANCEL_MODELS = 'models:CANCEL_MODELS';
export const GET_HOME_MODELS = 'models:GET_HOME_MODELS';

//Login Constants
export const LOGIN_SUCCESS = 'login:LOGIN_SUCCESS';
export const LOGIN_FAILED = 'login:LOGIN_FAILED';
export const LOGIN_WAIT = 'login:LOGIN_WAIT';
export const REGISTER_SUCCESS = 'login:REGISTER_SUCCESS';
export const REGISTER_FAILED = 'login:REGISTER_FAILED';
export const REGISTER_WAIT = 'login:REGISTER_WAIT';
export const LOGOUT_SUCCESS = 'login:LOGOUT_SUCCESS';
export const LOGIN_AUTHORIZE = 'login:LOGIN_AUTHORIZE';
