import React from 'react';

class TermsAndConditions extends React.Component {

  render() {
    return (
      <section className="termsAndConditions">
        <section className="termsAndConditions-container">
          <div className="block">
            <div className="block-container">
              <h1 className="block-title">Terms and Conditions</h1>
              <div className="block-body">
                <p className="block-txt">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                  sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
                  nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                  reprehenderit in voluptate velit esse cillum dolore eu fugiat
                  nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                  sunt in culpa qui officia deserunt mollit anim id est laborum.
                </p>
              </div>
            </div>
          </div>
          <div className="block">
            <div className="block-container">
              <h1 className="block-title">18 U.S.C 2257 Record-Keeping Requirements Compliance Statement </h1>
              <div className="block-body">
                <p className="block-txt">
                  Sed ut perspiciatis unde omnis iste natus
                  error sit voluptatem accusantium doloremque laudantium, totam rem aperiam,
                  eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae
                  vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur
                  aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione
                  voluptatem sequi nesciunt.
                </p>
              </div>
            </div>
          </div>
        </section>
      </section>
    );
  }
}

export default TermsAndConditions;
