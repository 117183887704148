import React from 'react';

class ItemBox extends React.Component {
  shouldComponentUpdate(nextProps) {
    return nextProps.model != this.props.model;
  }

  changeLocation() {
    this.props.onChangeLocation(`/Chat/${this.props.model.StageName}`);
  }

  render() {
    const model = this.props.model;
    return (
        <li className="itembox-options" onClick={this.changeLocation.bind(this)}>
          <img className="itembox-img" src={model.PosterUrl} />
          <div className="itembox-name">{model.StageName}</div>
        </li>
    );
  }
}

ItemBox.propTypes = {
  model: React.PropTypes.object.isRequired
}

export default ItemBox;
