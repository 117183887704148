import ClientData from './ClientData';
import {fromJS} from 'immutable';

const initialState = window.__INITIAL_STATE__;
var globalState = loadState();

function loadState() {
  try {
    let state = ClientData.get('state');
    if (state && state.updatedOn) {
      let currentDate = new Date();
      let lastUpdated = new Date(state.updatedOn);
      if (currentDate.getTime() - lastUpdated.getTime() > 10) {
        ClientData.unset('state');
        state = null;
      }
      return state;
    }
  }
  catch(err) {
    console.log(err);
    ClientData.unset('state');
  }
  return null;
};

export default () => {
  if (globalState) {
    initialState.models.filters = globalState.models.filters;
  }
  return fromJS(initialState);
}
