import SideBar from '../components/ChatScreen/SideBar';
import { connect } from 'react-redux';

function mapStateToProps(state, ownProps){
  return {
    models: state.getIn(['models', 'modelList'])
                .filter((model) => model.get('StageName') != ownProps.performerName)
                .sort((a, b) => a.get('Users') < b.get('Users'))
                .take(4),
    activeChat: state.getIn(['chat', 'messageList']).size > 0
  }
}

export default connect(mapStateToProps)(SideBar);
