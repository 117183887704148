import * as actions from '../CONSTANTS';
import { Map, fromJS} from 'immutable';

function waitModel(state) {
  return state.set('isWaitingModels', true);
}

function filterModels(state, newFilterList) {
  if (newFilterList) state = state.set('filters', fromJS(newFilterList));
  let filterList = state.get('filters');
  let filteredModels = state.getIn(['modelList']).filter((model) => {
    return filterList.isSubset(model.get('Tags'));
  });
  return state.setIn(['modelList'], filteredModels);
}

function getModels(state, newModels) {
  return filterModels(state.mergeIn(['modelList'], fromJS(newModels)).delete('isWaitingModels'));
}


export default (state = Map(), action) => {
  switch (action.type) {
    case actions.LOAD_MODELS:
      return getModels(state, action.newModels );
    case actions.WAIT_MODELS:
      return waitModel(state);
    case actions.FILTER_MODELS:
      return filterModels(state, action.newFilters);
    default:
      return state;
  }
}
