/*eslint-disable no-unused-vars*/
import React from 'react';
import Dialog from '../Dialog/Dialog';
/*eslint-disable no-unused-vars*/
import classNames from 'classnames';

class SignonVideo extends React.Component {

  renderSignUp(isOpen){
    let classes = classNames('signonVideo', {open: isOpen});
    return (
      <section className={classes}>
        <div className="signonVideo-header">
          <h2 className="signonVideo-title">Sign Up</h2>
          <a className="signonVideo-close" onClick={this.props.closeChatDialog.bind(this, 'privateSignOn')}>X</a>
        </div>
        <div className="signonVideo-body">
          <div className="signonVideo-body-container">
            <p className="signonVideo-text">Sign Up now and take a look into<br /> the Private Chat for free</p>
          </div>
          <div className="signonVideo-body-container">
            <a className="signonVideo-button button button-middle" onClick={this.props.openMainDialog.bind(this,'join')}>Sign Up</a>
          </div>

        </div>
        <div className="signonVideo-footer">
          <a className="signonVideo-link" onClick={this.props.openMainDialog.bind(this,'login')}>Or Login</a>
        </div>
      </section>
    );
  }

  renderGetPrivate(isOpen){
    let classes = classNames('signonVideo', {open: isOpen});
    return (
      <section className={classes}>
        <div className="signonVideo-header">
          <h2 className="signonVideo-title">Private show with Serene</h2>
          <a className="signonVideo-close" onClick={this.props.closeChatDialog.bind(this, 'privateSignOn')}>X</a>
        </div>
        <div className="signonVideo-body">
          <div className="signonVideo-body-container">
            <p className="signonVideo-text">9999 Credits per minute</p>
          </div>
          <div className="signonVideo-body-container">
            <a className="signonVideo-button button button-middle" href="#">Start Private Show</a>
          </div>
          <div className="signonVideo-body-container">
            <p className="signonVideo-text min"><input type="checkbox" /> Enable Two-way Audio for +2000 credits per minute</p>
          </div>

        </div>
        <div className="signonVideo-footer">
          <p className="signonVideo-text">You have <span className="bold">30</span> credits left</p>
        </div>
      </section>
    );
  }

  render(){
    let isOpen = this.props.chatDialogs.get('privateSignOn');
    let logged = this.props.logged;
    if (logged)
      return this.renderGetPrivate(isOpen);
    else
      return this.renderSignUp(isOpen);
  }
}

SignonVideo.propTypes = {
  mainDialogs: React.PropTypes.object.isRequired,
  chatDialogs: React.PropTypes.object.isRequired,
  openMainDialog: React.PropTypes.func.isRequired,
  closeMainDialog: React.PropTypes.func.isRequired,
  openChatDialog: React.PropTypes.func.isRequired,
  closeChatDialog: React.PropTypes.func.isRequired,
  logged: React.PropTypes.bool
}

export default SignonVideo;
