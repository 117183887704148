/*eslint-disable no-unused-vars*/
import React from 'react';
import { Link } from 'react-router';
import JPGStreamer from '../Video/JPGStreamer';
/*eslint-enable no-unused-vars*/
import getVideoSource from '../Video/common/videoSource';

class ModelBox extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      videoPlaying: false
    };
  }

  playVideo() { this.setState({ videoPlaying: true }); }

  stopVideo() { this.setState({ videoPlaying: false }); }

  render() {
    let currentModel = this.props.model;
    let videoSource = getVideoSource(currentModel, true);
    return (
      <div className="modelbox-interna"
        onMouseOver={() => {this.playVideo();}}
        onMouseOut={() => {this.stopVideo();}}>
        <Link to={`/Chat/${currentModel.StageName}`}>
          { this.state.videoPlaying ?
            < JPGStreamer posterUrl={currentModel.PosterUrl} videoSource={videoSource} callBacks={ {videoPlaying: () => {;} } }/>
            : <img src={currentModel.PosterUrl} />
          }
          <div className="modelbox-name">{currentModel.StageName}</div>
        </Link>
      </div>
    );
  }
}

ModelBox.propTypes = {
  model: React.PropTypes.object
}

export default ModelBox;
