import SignonVideo from '../components/ChatScreen/SignonVideo';
import { connect } from 'react-redux';
import { dialogsActions } from '../redux/actions';

function mapStateToProps(state){
  return {
    mainDialogs: state.getIn(['dialogs', 'main']),
    chatDialogs: state.getIn(['dialogs', 'chat']),
    activeChat: state.getIn(['chat', 'messageList']).size > 0
  }
}

export default connect( mapStateToProps , dialogsActions )(SignonVideo);
