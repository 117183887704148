/*eslint-disable no-unused-vars*/
import React from 'react';
import VideoWrapper from '../Video/VideoWrapper';
import SignonVideo from '../../containers/SignonVideo';
/*eslint-enable no-unused-vars*/

class VideoBox extends React.Component {
  shouldComponentUpdate(nextProps) {
    return nextProps.model != this.props.model;
  }

  render() {
    return (
      <section className="videobox-container">
        <section className="videobox-player">
          < VideoWrapper model={this.props.model.toJS()} />
          < SignonVideo />
        </section>
      </section>
    );
  }
}

VideoBox.propTypes = {
  model: React.PropTypes.object.isRequired
}

export default VideoBox;
