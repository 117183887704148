import React from 'react';
/*eslint-disable no-unused-vars*/
import { Link } from 'react-router';
import {ccbillPaymentForm, plans} from '../../../../siteconfig.json';

class Payments extends React.Component {

  constructor(props) {
    super(props);
    this.state = {step: 1, plan: 0};
    this.ccbillBaseUrl = ccbillPaymentForm;
    this.plans = plans;
    this.ccbillPlanUrl = "";
  }

  setStep(s){
    this.setState({step: s});
  }

  selectPlan(planId){
    this.ccbillPlanUrl = this.ccbillBaseUrl + this.plans[planId||0].flexId;
    this.setState({step: 2, plan: planId});
  }

  goBack() {
    const { router } = this.context;
    if (document.referrer && document.referrer.length > 0)
      router.replace('/');
    else
      router.goBack();
  }

  renderStep1(){
    //Render Plans
    return (
      <div>
        <div className="payments-planes">
        {
          this.plans.map((pack,i)=>{
            let freeCreditsStyle={
              opacity:pack.bonus>0?1:0
            }
            return (
              <div className="payments-plane" onClick={this.selectPlan.bind(this,i)} key={pack.name}>
                <div className="payments-planeTop">
                  <h2 className="payments-planeTitle1" style={freeCreditsStyle}>{ '+ '+pack.bonus }</h2>
                  <p className="payments-planeTitle2">{ pack.bonus>0?('Free credits'):'No extra credits' }</p>
                  <p className="payments-planeTitle3">{ pack.bonus>0?('Bonus unlocked'):'Bonus locked' }</p>
                </div>
                <div className="payments-planeBottom">
                  <p className="payments-planeNumCredits">{pack.credits}</p>
                  <p className="payments-planeCredits">credits</p>
                  <input className="payments-planeButton button button-middle" type="button" value="Buy Credits" />
                  <p className="payments-planePrice">$ {pack.price}</p>
                </div>
              </div>
            );
          })
        }
        </div>
        <div className="payments-cards">
          <img src="../images/visa.png" />
          <img src="../images/mastercard.png" />
          <img src="../images/maestro.png" />
          <img src="../images/jcb.png" />
          <img src="../images/discover.png" />
          <img src="../images/diners.png" />
          <img src="../images/delta.png" />
          <img src="../images/switch.png" />
        </div>
      </div>
    );
  }

  renderStep2(){
    //Render CCBill iFrame form
    return (
      <div className="ccbill-frameContainter">
        <iframe className="ccbill-frame" src={this.ccbillPlanUrl}></iframe>
      </div>
    );
  }

  render() {
    return (
      <section className="payments">
        <section className="payments-container">
          <div className="block">
            <div className="block-container">
              <h1 className="block-title">
                <span>Buy credits with your card - One time charge</span>
                <a onClick={this.goBack.bind(this)} className="payments-closeButton"></a>
              </h1>
              <div className="block-body">
                <div className="payments-steps">
                  <a className="payments-step show" onClick={this.setStep.bind(this,1)}><span>1</span> Package Selection</a>
                  { this.state.step>=2?(<a className="payments-step show"><span>2</span> Payment Details</a>):'' }
                </div>
                {
                  (()=>{
                    switch (this.state.step) {
                      case 1:
                        return this.renderStep1();
                      case 2:
                        return this.renderStep2();
                      default:
                        return this.renderStep1();
                    }
                  })()
                }
              </div>
            </div>
          </div>
        </section>
      </section>
    );
  }
}
Payments.contextTypes = {
  router: React.PropTypes.object.isRequired
}

export default Payments;
