import React from 'react';
/*eslint-disable no-unused-vars*/
import ChatInput from './ChatInput';
import ScrollArea from 'react-scrollbar';
/*eslint-enable no-unused-vars*/
class ChatTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      messages: []
    };
  }

  componentWillReceiveProps(nextProps) {
    let newMessages = nextProps.messages
              .sort((a, b) => a['timestamp'] > b['timestamp'])
              .takeLast(15);
    this.setState({
      messages: newMessages
    });
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.messages != this.props.messages;
  }

  render() {
    let messages = this.state.messages;
    return (
      <section>
        <ScrollArea speed={0.8} className="chatbox-content" contentClassName="chatbox-content-s" horizontal={false}>
          <section>
            {
              messages.map((msg, index) => {
                if (msg.isModel)
                  return <span key={index} className="chatbox-msg-client">{`${msg.from.username}: ${msg.message}`}</span>
                else
                  return <span key={index} className="chatbox-msg-user">{`${msg.from.username}: ${msg.message}`}</span>
              })
            }
          </section>
        </ScrollArea>
        < ChatInput waiting={this.props.waiting} sendChat={this.props.sendChat} />
      </section>
    );
  }
}
ChatTable.contextTypes = {
  scrollArea: React.PropTypes.object
};

ChatTable.propTypes = {
  messages: React.PropTypes.object,
}

export default ChatTable;
