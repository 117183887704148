import React from 'react';

class TermsBox extends React.Component {
  render() {
    return (
      <section className="termsbox-box">
        <section className="termsbox-container">
      The site contains sexually explicit material. Enter ONLY if you are at least 18 years old and agree to our cookie rules.
18 U.S.C. 2257 Record-Keeping Requirements Compliance Statement - All Models are at least 18 years old.
        </section>
      </section>
    );
  }
}

export default TermsBox;
