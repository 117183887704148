/*eslint-disable no-unused-vars*/
import React from 'react';
import LogoBar from './LogoBar';
import LinkBar from './LinkBar';
import loginActions from '../../redux/actions/loginActions';
/*eslint-enable no-unused-vars*/


class Header extends React.Component {

  componentDidMount(){
    this.props.loginAttempt.bind(this)();
  }

  render() {
    return (
      <section className="header-element">
          <section className="header-boxsup">
            <section className="header-container">
              < LogoBar />
              < LinkBar {...this.props} />
            </section>
          </section>
      </section>
    );
  }
}

export default Header;
