import React from 'react';
import { findDOMNode } from 'react-dom';

class ChatInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = { userMessage: '' };
  }

  componentWillMount() { this.setState({ userMessage: '' }); }

  componentWillReceiveProps(nextProps) { if (!nextProps.waiting) this.setState({ userMessage: '' }); }

  componentDidUpdate(){ findDOMNode(this.refs.chatInput).focus(); }

  setMessage(event) { this.setState({ userMessage: event.target.value }); }

  handleKey(event) {
    if (event.charCode == 13)
      this.sendMessage();
    else if (event.keyCode == 13)
      this.sendMessage();
  }

  sendMessage() {
    this.props.sendChat(this.state.userMessage);
    this.setState({ userMessage: 'Sending...' });
  }

  render() {
    let waiting = this.props.waiting;
    return (
      <div className="chatbox-input">
        <input type="text" className="chatbox-userinput"
              disabled={waiting}
              onChange={this.setMessage.bind(this)}
              value={this.state.userMessage}
              onKeyPress={this.handleKey.bind(this)}
              ref="chatInput"
              />
        <button type="button"
            className="chatbox-button"
            onClick={this.sendMessage.bind(this)}
            disabled={waiting}
            >
            <img align="left" src="../images/send.png" width="24px" height="24px" />
        </button>
      </div>
    )
  }
}

ChatInput.propTypes = {
  sendChat: React.PropTypes.func.isRequired,
  waiting: React.PropTypes.bool.isRequired
}

export default ChatInput;
