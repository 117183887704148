import rootApp from '../shared/rootApp';
import PerformerUtils from '../shared/utils/PerformerUtils';
import { render } from 'react-dom';


PerformerUtils.getPerformers()
  .then((result) => {
    window.__INITIAL_STATE__.models.modelList = result;
    render(rootApp, document.getElementById('root'));
  })
  .catch((err) => {
    console.error(err);
    render(rootApp, document.getElementById('root'));
  });
