/*eslint-disable no-unused-vars*/
import React from 'react';
import VideoBox from './VideoBox';
import SideBar from '../../containers/SideBar';
import ChatSocket from '../../containers/ChatSocket';
import ChatBar from './ChatBar';
import ModelInfo from './ModelInfo';
import { ConfirmDialogContainer } from '../Dialog/ConfirmDialog';
/*eslint-enabled no-unused-vars*/

class ChatScreen extends React.Component {

  constructor(props) {
    super(props);
    this.state = { goLocation: '' };
  }

  componentWillMount() {
    const { router } = this.context;
    if (this.props.model == null)
      router.push('/');
    else {
      this.props.joinRoom(this.props.model.get('StageName'));
    }
  }

  componentWillReceiveProps(nextProps) {
    const { router } = this.context;
    if (nextProps.model != this.props.model)
      this.props.joinRoom(nextProps.model.get('StageName'));
  }

  getToLocation(){
    return this.state.goLocation;
  }

  onChangeLocation(newLocation, confirmChange) {
      const { router } = this.context;
      if (confirmChange) {
        this.setState({ goLocation: newLocation });
        this.props.openLocationDialog('confirm');
      }else{
        this.props.closeLocationDialog('confirm');
        router.push(newLocation);
      }
    }

  shouldComponentUpdate(nextProps) {
    return nextProps.model != this.props.model;
  }

  render() {
    if (this.props.model == null) return <section></section>;
    return (
      <section className="chatscreen-element">
        <ChatSocket/>
        <section className="chatscreen-container">
          <h1 className="chatscreen-modelName">{this.props.model.get('StageName')}</h1>
          <section className="chatscreen-bigPhotos">
            < VideoBox
                model={this.props.model}
            />
            < SideBar performerName={this.props.model.get('StageName')} onChangeLocation={this.onChangeLocation.bind(this)}/>
          </section>
          <section className="chatscreen-chatOptions">
            < ChatBar
                model={this.props.model}
                openChatDialog={this.props.openChatDialog}
                closeChatDialog={this.props.closeChatDialog}
                waiting={this.props.waiting}
                sendChat={this.props.sendChat}
                />
          </section>
        </section>
        < ModelInfo model={this.props.model} />
        <ConfirmDialogContainer stageName={this.props.model.get('StageName')} onChangeLocation={this.onChangeLocation.bind(this)} getToLocation={this.getToLocation.bind(this)} />
      </section>
    );
  }
}
ChatScreen.contextTypes = {
  router: React.PropTypes.object.isRequired
}

ChatScreen.propTypes = {
  model: React.PropTypes.object.isRequired,
  dialogs: React.PropTypes.object.isRequired
}

export default ChatScreen;
