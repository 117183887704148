/*eslint-disable no-unused-vars*/
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
/*eslint-enable no-unused-vars*/
import React from 'react';

class Dialog extends React.Component{
  render(){
    let transitionEnterTimeout = this.props.transitionEnterTimeout || 500;
    let transitionLeaveTimeout = this.props.transitionLeaveTimeout || 300;
    if( this.props.isOpen ){
      return(
        <ReactCSSTransitionGroup transitionName={this.props.transitionName} transitionEnterTimeout={transitionEnterTimeout} transitionLeaveTimeout={transitionLeaveTimeout}>
          <div className="dialog">
            { this.props.children }
          </div>
        </ReactCSSTransitionGroup>
      );
    }else{
      return(
        <ReactCSSTransitionGroup transitionName={this.props.transitionName} transitionEnterTimeout={transitionEnterTimeout} transitionLeaveTimeout={transitionLeaveTimeout} />
      );
    }
  }
}

export default Dialog;
