export const JWPlayer = "JWPlayer";
export const Theoplayer = "Theoplayer";
export const JPGStreamer = "JPGStreamer";
export const VideoTag = "VideoTag";

export const ResolutionProfiles = {
  Pro720p: 'Pro720p',
  Pro480p: 'Pro480p',
  Pro360p: 'Pro360p',
  Pro240p: 'Pro240p',
  Pro160p: 'Pro160p',
  Offline: 'Offline'
}

export const DeliveryProfiles = {
	Pro720p: "pentad",
	Pro480p: "routine",
	Pro360p: "troika",
	Pro240p: "lousy",
	Offline: "quaint"
}

export const EncodingProfiles = {
	MACHETE: "machete",
	LOOPITA: "loopita",
	JIMBEAM: "jimbeam",
	SMASH: "smash",
	CRAMP: "cramp",
	RETROFIT: "retrofit",
	WEEKEND: "weekend"
}

export const DeliveryProfileExceptions = [
	{ "profile": EncodingProfiles.JIMBEAM, "notSupportedRes": [ResolutionProfiles.Pro720p], "minimumSupported": ResolutionProfiles.Pro480p },
	{ "profile": EncodingProfiles.SMASH, "notSupportedRes": [ResolutionProfiles.Pro720p], "minimumSupported": ResolutionProfiles.Pro480p },
  { "profile": EncodingProfiles.WEEKEND, "notSupportedRes": [ResolutionProfiles.Pro720p], "minimumSupported": ResolutionProfiles.Pro480p },
	{ "profile": EncodingProfiles.CRAMP, "notSupportedRes": [ResolutionProfiles.Pro720p, ResolutionProfiles.Pro480p], "minimumSupported": ResolutionProfiles.Pro360p },
	{ "profile": EncodingProfiles.RETROFIT, "notSupportedRes": [ResolutionProfiles.Pro720p, ResolutionProfiles.Pro240p], "minimumSupported": ResolutionProfiles.Offline }
]
