/*eslint-disable no-unused-vars*/
import React from 'react';
import ModelBox from './ModelBox';
/*eslint-enable no-unused-vars*/

class TemplateRow extends React.Component {

  render9regular(modelList) {
    return (
      <section className="templaterow-container">
        <div className="templaterow-first_column">
          <div className="templaterow-completa">
            <ModelBox model={modelList[0]} />
          </div>
          <div className="templaterow-tercera">
            <ModelBox model={modelList[1]} />
          </div>
          <div className="templaterow-tercera">
            <ModelBox model={modelList[2]} />
          </div>
          <div className="templaterow-tercera">
            <ModelBox model={modelList[3]} />
          </div>
        </div>
        <div className="templaterow-second_column">
          <div className="templaterow-media">
            <ModelBox model={modelList[4]} />
          </div>
          <div className="templaterow-media">
            <ModelBox model={modelList[5]} />
          </div>
          <div className="templaterow-completa">
            <ModelBox model={modelList[6]} />
          </div>
          <div className="templaterow-media">
            <ModelBox model={modelList[7]} />
          </div>
          <div className="templaterow-media">
            <ModelBox model={modelList[8]} />
          </div>
        </div>
      </section>
    );
  }

  render9mirror(modelList) {
    return (
      <section className="templaterow-container">
        <div className="templaterow-second_column">
          <div className="templaterow-media">
            <ModelBox model={modelList[0]} />
          </div>
          <div className="templaterow-media">
            <ModelBox model={modelList[1]} />
          </div>
          <div className="templaterow-completa">
            <ModelBox model={modelList[2]} />
          </div>
          <div className="templaterow-media">
            <ModelBox model={modelList[3]} />
          </div>
          <div className="templaterow-media">
            <ModelBox model={modelList[4]} />
          </div>
        </div>
        <div className="templaterow-first_column">
          <div className="templaterow-completa">
            <ModelBox model={modelList[5]} />
          </div>
          <div className="templaterow-tercera">
            <ModelBox model={modelList[6]} />
          </div>
          <div className="templaterow-tercera">
            <ModelBox model={modelList[7]} />
          </div>
          <div className="templaterow-tercera">
            <ModelBox model={modelList[8]} />
          </div>
        </div>
      </section>
    );
  }


  render9(modelList, mirror = false) {
    return mirror ? this.render9mirror(modelList) : this.render9regular(modelList);
  }

  render7regular(modelList) {
    return (
      <section className="templaterow-container">
        <div className="templaterow-first_column">
          <div className="templaterow-completa">
            <ModelBox model={modelList[0]} />
          </div>
        </div>
        <div className="templaterow-second_column">
          <div className="templaterow-media">
            <ModelBox model={modelList[1]} />
          </div>
          <div className="templaterow-media">
            <ModelBox model={modelList[2]} />
          </div>
          <div className="templaterow-completa">
            <ModelBox model={modelList[3]} />
          </div>
        </div>
        <div className="templaterow-big_column">
          <div className="templaterow-tercera">
            <ModelBox model={modelList[4]} />
          </div>
          <div className="templaterow-tercera">
            <ModelBox model={modelList[5]} />
          </div>
          <div className="templaterow-tercera">
            <ModelBox model={modelList[6]} />
          </div>
        </div>
      </section>
    );
  }

  render7mirror(modelList) {
    return (
      <section className="templaterow-container">
        <div className="templaterow-second_column">
          <div className="templaterow-media">
            <ModelBox model={modelList[0]} />
          </div>
          <div className="templaterow-media">
            <ModelBox model={modelList[1]} />
          </div>
          <div className="templaterow-completa">
            <ModelBox model={modelList[2]} />
          </div>
        </div>
        <div className="templaterow-first_column">
          <div className="templaterow-completa">
            <ModelBox model={modelList[3]} />
          </div>
        </div>
        <div className="templaterow-big_column">
          <div className="templaterow-tercera">
            <ModelBox model={modelList[4]} />
          </div>
          <div className="templaterow-tercera">
            <ModelBox model={modelList[5]} />
          </div>
          <div className="templaterow-tercera">
            <ModelBox model={modelList[6]} />
          </div>
        </div>
      </section>
    );
  }

  render7(modelList, mirror = false) {
    return mirror ? this.render7mirror(modelList) : this.render7regular(modelList);
  }


  render5regular(modelList) {
    return (
      <section className="templaterow-container">
        <div className="templaterow-second_column">
          <div className="templaterow-completa">
            <ModelBox model={modelList[0]} />
          </div>
          <div className="templaterow-completa">
            <ModelBox model={modelList[1]} />
          </div>
          <div className="templaterow-completa">
            <ModelBox model={modelList[2]} />
          </div>
        </div>
        <div className="templaterow-first_column">
          <div className="templaterow-completa">
            <ModelBox model={modelList[3]} />
          </div>
          <div className="templaterow-completa">
            <ModelBox model={modelList[4]} />
          </div>
        </div>
      </section>
    );
  }

  render5mirror(modelList) {
    return (
      <section className="templaterow-container">
        <div className="templaterow-first_column">
          <div className="templaterow-completa">
            <ModelBox model={modelList[0]} />
          </div>
          <div className="templaterow-completa">
            <ModelBox model={modelList[1]} />
          </div>
        </div>
        <div className="templaterow-second_column">
          <div className="templaterow-completa">
            <ModelBox model={modelList[2]} />
          </div>
          <div className="templaterow-completa">
            <ModelBox model={modelList[3]} />
          </div>
          <div className="templaterow-completa">
            <ModelBox model={modelList[4]} />
          </div>
        </div>
      </section>
    );
  }

  render5(modelList, mirror = false) {
    return mirror ? this.render5mirror(modelList) : this.render5regular(modelList);
  }

  render() {
    let modelList = this.props.modelList;
    let mirror = this.props.mirror;

    if(modelList.length >= 9){
      return this.render9(modelList, mirror);
    }else if(modelList.length == 7){
      return this.render7(modelList, mirror);
    }else if(modelList.length <= 5){
      return this.render5(modelList, mirror);
    }
  }
}

TemplateRow.propTypes = {
  onClick: React.PropTypes.func,
  modelList: React.PropTypes.array
}

export default TemplateRow;
