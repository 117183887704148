import * as actions from '../CONSTANTS';
import { Map, fromJS} from 'immutable';

export default (state = Map(), action) => {
  switch (action.type) {
    case actions.CONNECTION_SUCCESSFUL:
      return state.updateIn( ['connected'], value => true);
    case actions.WAITING_MESSAGE:
      return state.updateIn( ['waiting'] , value => true);
    case actions.WAITING_JOIN:
      return state.updateIn( ['waiting'], value => false)
                  .updateIn( ['roomName'] , value => action.roomName)
                  .updateIn( ['messageList'], msgArr => fromJS([]));
    case actions.ECHO_ROOM:
      return state.get('joined') ? state : state.updateIn( ['joined'], value => true);
    case actions.SOMEBODY_JOIN_ROOM:
      return state;
    case actions.ECHO_MESSAGE:
      return state.updateIn( ['waiting'], value => false);
    case actions.RECEIVE_MESSAGE:
      return state.updateIn( ['messageList'], msgArr => {
          if (msgArr.size > 30) msgArr = msgArr.shift();
          return msgArr.push(action.message);
        });
    default:
      return state;
  }
  return state;;
}
