import { Map } from 'immutable';
import * as actions from '../CONSTANTS';

export default (state = Map(), action) => {
  let newState = state;
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      return state.updateIn(['logged'],(value) => true ).updateIn(['username'],(value) => action.token.UserName);
    case actions.LOGOUT_SUCCESS:
      return state.updateIn(['logged'],(value) => false ).updateIn(['username'],(value) => "Guest" );
    default:
      return state;
  }
  return newState;
}
