/*eslint-disable no-unused-vars*/
import React from 'react';
import ChatTable from '../../containers/ChatTable';
/*eslint-enable no-unused-vars*/
import classNames from 'classnames';

class ChatBox extends React.Component {

  closeChat(){ this.props.closeChat(); }

  renderGuest() {
    let classes = classNames('chatbox-container', {open: Boolean(this.props.open)});
    return (
      <section className={classes}>
        <a className="chatbox-identifier" onClick={this.closeChat.bind(this)}>
          <img align="left" src="../images/chat_bubbles.png" width="24px" />
          <span className="chatbox-model">{this.props.model.get('StageName')}'s Chat</span>
          <img align="right" src="../images/down_arrow.png" width="24px" />
        </a>
        < ChatTable />
      </section>
    );
  }

  renderPrivate() {
    let classes = classNames('chatbox-container', {open: Boolean(this.props.open)});
    return (
      <section className={classes}>
        <a className="chatbox-identifier" onClick={this.closeChat.bind(this)}>
          <img align="left" src="../images/chat_bubbles.png" width="24px" />
          <span className="chatbox-model">{this.props.model.get('StageName')}'s Chat</span>
          <img align="right" src="../images/down_arrow.png" width="24px" />
        </a>
        < ChatTable />
      </section>
    );
  }

  render() {
    let logged = this.props.logged;
    if (logged == true)
      return this.renderPrivate();
    else
      return this.renderGuest();
  }
}

ChatBox.propTypes = {
  model: React.PropTypes.object.isRequired,
  closeChat: React.PropTypes.func.isRequired,
  open: React.PropTypes.bool,
  logged: React.PropTypes.bool,
}

export default ChatBox;
