import React from 'react';
import * as VideoConstants from './common/videoConstants';

//TODO: remove controls and probably autobuffer, are only for testing

let videoIsPlaying = false;

class VideoTag extends React.Component {

  constructor(props){
    super(props);

    this.timeUpdateHandler = this.timeUpdateHandler.bind(this);
    this.errorHandler = this.errorHandler.bind(this);
    this.playingHandler = this.playingHandler.bind(this);
  }

  componentDidMount(){
    let video = document.getElementById('videoTagId');
    video.addEventListener('timeupdate', this.timeUpdateHandler);
    video.addEventListener('playing', this.playingHandler);
    video.addEventListener('error', this.errorHandler);
  }

  componentWillUnmount(){
    let video = document.getElementById('videoTagId');
    video.removeEventListener('timeupdate', this.timeUpdateHandler);
    video.removeEventListener('playing', this.playingHandler);
    video.removeEventListener('error', this.errorHandler);
  }

  playingHandler(){
    let checkedTimes = 0, maxTimes = 5;

    function checkIfPlaying() {
      if(!videoIsPlaying){
        if(checkedTimes < maxTimes){
          checkedTimes++;
          setTimeout(checkIfPlaying, 1000);
        }else{
          this.props.callBacks.videoFailed(VideoConstants.VideoTag, 'Video not playing');
        }
      }else{
        this.props.callBacks.videoPlaying();
      }
    };

    checkIfPlaying = checkIfPlaying.bind(this);
    checkIfPlaying();

  }

  timeUpdateHandler(){
    videoIsPlaying = true;
    event.target.removeEventListener('timeupdate', this.timeUpdateHandler);
  }

  errorHandler(){
    this.props.callBacks.videoFailed(VideoConstants.VideoTag, event.message);
  }

  render() {
    return (
      <video autobuffer autoPlay controls className='video-tag' id='videoTagId'
        webkit-playsinline='1'
        src={this.props.videoSource} preload='none'>
          <source src={this.props.videoSource} type='video/mp4' />
          "Your browser does not support the video tag"
      </video>
    );
  }
}

VideoTag.propTypes = {
  callBacks: React.PropTypes.object.isRequired,
  videoSource: React.PropTypes.string.isRequired
}

export default VideoTag;
