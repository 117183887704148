/*eslint-disable no-unused-vars*/
import Dialog from './Dialog';
/*eslint-enable no-unused-vars*/
import React from 'react';
import { connect } from 'react-redux';
import dialogsActions from '../../redux/actions/dialogsActions';
import loginActions from '../../redux/actions/loginActions';

class LoginDialog extends React.Component{

  populateState( event ){
    let obj = {};
    obj[event.target.name]=event.target.value;
    this.setState( obj );
  }

  login(){
    this.props.loginAttempt( { UserName : this.state.username , Password:this.state.password } );
  }

  render(){
    return (
      <Dialog isOpen={this.props.dialogs.getIn(['login'])} transitionName="dialog-anim">
        <div className="loginDialog">
          <div className="dialog-head">
            <h3 className="dialog-title">Login</h3>
            <button className="dialog-close" onClick={this.props.closeMainDialog.bind(this,'login')}>X</button>
          </div>
          <div className="dialog-body">
            <p className="dialog-txt">Welcome to this site</p>
            <input className="dialog-input" type="text" name="username" placeholder="username" onChange={this.populateState.bind(this)}/>
            <input className="dialog-input" type="password" name="password" placeholder="password" onChange={this.populateState.bind(this)}/>
          </div>
          <div className="dialog-footer">
            <div className="dialog-loginOptions">
              <label className="dialog-keepme"><input className="dialog-keepmeCheck" type="checkbox" /> Keep me logged in</label>
              <button className="dialog-button button button-middle" onClick={this.login.bind(this)}>Login</button>
            </div>
            <p className="dialog-linkTxt"><a className="dialog-link" href="#">Click here</a> if you forgot your password!</p>
            <p className="dialog-linkTxt">Don&#39;t have an account? <a className="dialog-link" onClick={this.props.openMainDialog.bind(this,'join')}>Join now!</a></p>
          </div>
        </div>
      </Dialog>
    );
  }

}

function mapStateToProps(state){
  return {
    dialogs:state.getIn(['dialogs','main']),
    user: state.getIn(['user'])
  }
}

export {LoginDialog};
export const LoginDialogContainer = connect( mapStateToProps , Object.assign(dialogsActions,loginActions) )(LoginDialog);
