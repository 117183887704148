import * as actions from '../CONSTANTS';
import PerformerUtils from '../../utils/PerformerUtils';

//ActionCreator
export default {
  loadModels:   (filters) => getModels(filters)
}

//Logic (Thunks)
function getModels(filters) {
  return (dispatch, getState) => {
    if (filters) dispatch({ type: actions.FILTER_MODELS, newFilters: filters, manual: true });
    if (getState().getIn(['models', 'isWaitingModels'])) return null;
    dispatch({ type: actions.WAIT_MODELS });
    PerformerUtils.getPerformers()
      .then((result) => {
        dispatch({ type: actions.LOAD_MODELS, newModels: result });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: actions.CANCEL_MODELS });
      });
  };
}
