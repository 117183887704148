/*eslint-disable no-unused-vars*/
import React from 'react';
import JPGStreamer from './JPGStreamer';
import VideoTag from './VideoTag';
import JWPlayer from './JWPlayer';
/*eslint-enable no-unused-vars*/
import userAgent from './modules/userAgent';
import getVideoSource from './common/videoSource';
import * as VideoConstants from './common/videoConstants';

class VideoWrapper extends React.Component {

  constructor(props){
    super(props);

    this.videoFailedHandler = this.videoFailedHandler.bind(this);
    this.videoPlayingHandler = this.videoPlayingHandler.bind(this);

    this.callBacks = {
      videoFailed: this.videoFailedHandler,
      videoPlaying: this.videoPlayingHandler
    }
  }

  componentWillMount() {
    let isJPGStreamer = this.shouldUseJPGStreamer(),
        videoSource = getVideoSource(this.props.model, isJPGStreamer),
        videoPlayer = this.getVideoPlayer(this.callBacks, videoSource, isJPGStreamer);

    this.setState(
      {
        videoPlayer: videoPlayer,
        isPlaying: false
      });
  }

  componentWillReceiveProps(nextProps) {
    let isJPGStreamer = this.shouldUseJPGStreamer(),
        videoSource = getVideoSource(nextProps.model, isJPGStreamer),
        videoPlayer = this.getVideoPlayer(this.callBacks, videoSource, isJPGStreamer);

    this.setState(
      {
        videoPlayer: videoPlayer,
        isPlaying: false
      });
  }

  getVideoPlayer(callBacks, videoSource, isJPGStreamer){

    let videoComponent = < JWPlayer callBacks={callBacks} videoSource={videoSource} />;

    if(isJPGStreamer){
      videoComponent = < JPGStreamer callBacks={callBacks} videoSource={videoSource} />;
    }else if(userAgent.isMobile){
      videoComponent = < VideoTag callBacks={callBacks} videoSource={videoSource} />;
    }

    return videoComponent;
  }

  shouldUseJPGStreamer(){
    return userAgent.isMobile && !userAgent.isAndroid;
  }

  videoFailedHandler(videoType, message){
    console.error(`Video failed type:${videoType} message: ${message}`);

    if(videoType !== VideoConstants.JPGStreamer){
      let videoSource = getVideoSource(this.props.model, true);
      this.setState({
        videoPlayer: < JPGStreamer callBacks={this.callBacks} videoSource={videoSource} />,
        isPlaying: false
      });
    }else{
      throw new Error('Video not supported');
    }
  }

  videoPlayingHandler(){
    if(!this.state.isPlaying){
      this.setState(
        {
          videoPlayer: this.state.videoPlayer,
          isPlaying: true
        });
    }
  }

  render(){
    return (
      <div>
        {this.state.videoPlayer}
      </div>
    );
  }
}

VideoWrapper.propTypes = {
  model: React.PropTypes.object.isRequired
}

export default VideoWrapper;
