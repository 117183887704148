/*eslint-disable no-unused-vars*/
import React from 'react';
import SearchBox from './SearchBox';
/*eslint-enable no-unused-vars*/

class FilterBar extends React.Component {

  constructor(props) {
    super(props);
    this._optionFilters = [
      'sexy', 'latina', 'naked', 'hot', 'tits', 'friendly'
    ];
  }

  getSelectedFilters() {
    let filters = [];
    this._optionFilters.map((filterName) => {
      let isSelected = this.props.currentFilters.indexOf(filterName) >= 0 ? true : false;
      filters.push({name: filterName, selected: isSelected })
    })
    return filters;
  }

  clickFilter(option, remove) {
    let newFilterList = this.props.currentFilters;
    if (remove) {
      let i = newFilterList.indexOf(option);
      if (i != -1)
      	newFilterList.splice(i, 1);
    }
    else
      newFilterList.push(option);
    this.props.loadModels(newFilterList);
  }

  render() {
    let filterObjs = this.getSelectedFilters();
    return (
      <section>
        < SearchBox />
        <ul className="filterbar-list">
        {
          filterObjs.map((option, i) => {
              return <li className={option.selected ? 'filterbar-options-selected' : 'filterbar-options'} key={i}
              onClick={() => { this.clickFilter(option.name, option.selected) }}>{option.name}</li>;
          })
        }
        </ul>
      </section>
    );
  }
}

FilterBar.propTypes = {
  filters: React.PropTypes.array,
  loadModels: React.PropTypes.func.isRequired,
  currentFilters: React.PropTypes.array.isRequired
}

export default FilterBar;
