/*eslint-disable no-unused-vars*/
import React from 'react';
import Grid from './Grid';
import FilterBar from './FilterBar';
/*eslint-enable no-unused-vars*/

class Home extends React.Component {

  render() {
    return (
      <section>
        <section className="header-boxinf">
          < FilterBar
              currentFilters={this.props.filters.toJS()}
              loadModels={option => this.props.loadModels(option)}
            />
        </section>
        < Grid {...this.props} />
      </section>
    );
  }
}

export default Home;
