/*eslint-disable no-unused-vars*/
import React from 'react';
import {LoginDialogContainer} from '../Dialog/LoginDialog';
import {JoinDialogContainer} from '../Dialog/JoinDialog';
/*eslint-enable no-unused-vars*/

class LinkBar extends React.Component {

  renderLoggedOut(){
    return (
      <ul className="linkbar-list-user">
        <li className="linkbar-options-user" onClick={this.props.openMainDialog.bind(this,'login')}>Login</li>
        <li className="linkbar-options-user" onClick={this.props.openMainDialog.bind(this,'join')}>Join now</li>
      </ul>
    );
  }

  renderLoggedIn(){
    return (
      <ul className="linkbar-list-user">
        <li className="linkbar-options-user">Welcome { this.props.user.getIn(['username']) }</li>
        <li className="linkbar-options-user" onClick={ this.props.logoutAttempt.bind(this) }>Logout</li>
      </ul>
    );
  }


  render() {
    return (
      <section className="linkbar-container">
        {
          (()=>{
            switch ( this.props.user.getIn(['logged']) ) {
              case true:
                return this.renderLoggedIn();
              default:
                return this.renderLoggedOut();
            }
          })()
        }
        <LoginDialogContainer />
        <JoinDialogContainer />
      </section>
    );
  }
}


LinkBar.propTypes = {
  dialogs: React.PropTypes.object.isRequired,
  user: React.PropTypes.object.isRequired,
  openMainDialog: React.PropTypes.func.isRequired,
  closeMainDialog: React.PropTypes.func.isRequired,
  openChatDialog: React.PropTypes.func.isRequired,
  closeChatDialog: React.PropTypes.func.isRequired
}

export default LinkBar;
