/*eslint-disable no-unused-vars*/
import React from 'react';
import { Router, browserHistory  } from 'react-router';
import { Provider } from 'react-redux';
/*eslint-enable no-unused-vars*/
import { syncHistoryWithStore } from 'react-router-redux';
import store from './redux/configureStore';
import routes from './routes';

const history = syncHistoryWithStore(browserHistory, store, {
  selectLocationState: (state) => state.get('routing').toJS()
});

export default (
  <Provider store={store}>
    <Router history={history} routes={routes} />
  </Provider>
);
