import SocketManager from '../../utils/SocketManager';

const socketMiddleware = store => next => action => {
  if( action.emit ){
    SocketManager.emit( action.emit.event , action.emit.payload );
  }
  return next(action);
}

export default socketMiddleware;
