import React from 'react';

class ContactBar extends React.Component {
  render() {
    let linkcontacts = [
      {
        name: 'Contact Us',
        href: 'link1/',
        icon: false,
        path: ''
      },
      {
        name: 'Facebook',
        href: 'link2/',
        icon: true,
        path: '../images/fb.png'
      },
      {
        name: 'Instagram',
        href: 'link3/',
        icon: true,
        path: '../images/in.png'
      },
      {
        name: 'Twitter',
        href: 'link4/',
        icon: true,
        path: '../images/tw.png'
      },
      {
        name: 'F.A.Q',
        href: 'link5/',
        icon: false,
        path: ''
      }
    ];
    return (
    <section className="contactbar-box">
      <section className="contactbar-container">
          <ul className="contactbar-list">
          {
            linkcontacts.map((option, i) => {
                return (
                  <li className="contactbar-options" key={i}>
                    <a href="{option.href}" className="contactbar-link">
                    {
                      option.icon ? <img src={option.path} className="contactbar-image" /> : option.name
                    }
                    </a>
                  </li>
              );
            })
          }
          </ul>
        </section>
    </section>
    );
  }
}

export default ContactBar;
