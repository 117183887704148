/*eslint-disable no-unused-vars*/
import React from 'react';
import Header from './Header'
import Footer from './Footer'
/*eslint-enable no-unused-vars*/

class App extends React.Component {

  render() {
    return (
      <section className="home-container">
        < Header />
        {this.props.children}
        < Footer />
      </section>
    );
  }
}

export default App;
