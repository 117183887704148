class ArrayUtils {
  constructor() {
  }

  randomElem(array){
    return array[Math.floor(Math.random()*array.length)];
  }

  toArray(iterableObject) {
    let array = [];
    for(var item in iterableObject)
      array.push(iterableObject[item]);
    return array;
  }

}

export default new ArrayUtils();
