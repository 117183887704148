import dialogs from './dialogsReducer';
import models from './modelsReducer';
import user from './userReducer';
import chat from './chatReducer';
import location from './locationReducer';
import login from './loginReducer';
import routing from './routeReducer';
import { combineReducers } from 'redux-immutable';
import undo from './undoReducer';

export default undo(combineReducers({
  models,
  dialogs,
  user,
  chat,
  location,
  login,
  routing
}));
