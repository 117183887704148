/*eslint-disable no-unused-vars*/
import React from 'react';
import TemplateRow from './TemplateRow';
import ModelBox from './ModelBox';
/*eslint-enable no-unused-vars*/
import ArrayUtils from '../../utils/ArrayUtils';

class Grid extends React.Component {

  componentWillMount() {
    this.props.loadModels();
  }

  componentDidMount() {
     this.refresh = setInterval(() => {
       this.props.loadModels();
     }, 15000);
  }

  shouldComponentUpdate(nextProps) {
    return !nextProps.models.equals(this.props.models);
  }

  componentWillUnmount() {
    clearInterval(this.refresh);
  }

  groupModels() {
    let models = this.props.models.toJS();
    let modelsArray = ArrayUtils.toArray(models);

    if (modelsArray.length < 5) {
      return [];
    }

    let numbers = [9,7,5];
    let groupedModels = [];

    // /** Only for testing **/
    // for(var i=0; i<=37; i++)
    //   modelsArray.push(ArrayUtils.randomElem(modelsArray));

    //if we have more than 9 models, keep chunking randomly...
    while(modelsArray.length >= 9) {
      let number = ArrayUtils.randomElem(numbers);
      if (modelsArray.length >= number) {
        let chunk = modelsArray.splice(0, number)
        groupedModels.push(chunk);
      }
    }

    //if we can grab 7, lets do it (corner case #1)
    if(modelsArray.length <= 8 && modelsArray.length >= 7) {
      groupedModels.push(modelsArray.splice(0,7));
    }

    // if we can grab 5, lets do it (corner case #2)
    if(modelsArray.length <= 6 && modelsArray.length >= 5) {
      groupedModels.push(modelsArray.splice(0,5));
    }

    // if we have less than 5 and array is not empty...lets keep repeating random elements till we get 5.
    while(modelsArray.length < 5 && modelsArray.length > 0) {
      modelsArray.push(ArrayUtils.randomElem(modelsArray));
      if (modelsArray.length == 5) {
        groupedModels.push(modelsArray.splice(0,5));
      }
    }

    return groupedModels;
  }

  renderTemplates(groups) {
    return (
    <section className="grid-element">
      <section className="grid-container">
      {
        groups.map((rowGroup, index) => {
          return <TemplateRow key={index} modelList={rowGroup} mirror={index/2%0}/>
        })
      }
      </section>
    </section>
    );
  }

  renderSingles() {
    let currentModels = this.props.models.valueSeq().toJS();
    return (
    <section className="grid-element">
      <section className="grid-container">
        <section className="templaterow-container">
        {
          currentModels.map((model, index) => {
              return <div className="templaterow-cuarta" key={index}><ModelBox model={model} /></div>
          })
        }
        </section>
      </section>
    </section>
    );
  }

  render() {
    let modelGroups = this.groupModels();
    if (modelGroups.length > 0) {
      return this.renderTemplates(modelGroups);
    }
    else {
      return this.renderSingles();
    }
  }
}

Grid.propTypes = {
  models: React.PropTypes.object,
  loadModels: React.PropTypes.func.isRequired
}

export default Grid;
