export default function(reducer) {
  // Return a reducer that handles undo and redo
  //const initialState = reducer(undefined, {});

  return function (state = initialState, action) {
    switch (action.type) {
      case 'UNDO':
        return action.oldState;
      default:
        // Delegate handling the action to the passed reducer
        const newPresent = reducer(state, action);
        if (state === newPresent) {
          return state;
        }
        return reducer(state, action);
    }
  }
}
