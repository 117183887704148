/*eslint-disable no-unused-vars*/
import Dialog from './Dialog';
/*eslint-enable no-unused-vars*/
import React from 'react';
import { connect } from 'react-redux';
import dialogsActions from '../../redux/actions/dialogsActions';
import loginActions from '../../redux/actions/loginActions';

class JoinDialog extends React.Component{

  populateState( event ){
    let obj = {};
    obj[event.target.name]=event.target.value;
    this.setState( obj );
  }

  register(){
    this.props.registerAttempt( { UserName : this.state.username , Password:this.state.password , Cpassword : this.state.cpassword , Email : this.state.email } );
  }

  render(){
    return (
      <Dialog isOpen={this.props.dialogs.getIn(['join'])} transitionName="dialog-anim">
        <div className="joinDialog">
          <div className="dialog-head">
            <h3 className="dialog-title">Join Now!</h3>
            <button className="dialog-close" onClick={this.props.closeMainDialog.bind(this,'join')}>X</button>
          </div>
          <div className="dialog-body">
            <input className="dialog-input" type="text" name="username" placeholder="username" onChange={this.populateState.bind(this)} />
            <input className="dialog-input" type="text" name="email" placeholder="email" onChange={this.populateState.bind(this)}/>
            <input className="dialog-input" type="password" name="password" placeholder="password" onChange={this.populateState.bind(this)}/>
            <input className="dialog-input" type="password" name="cpassword" placeholder="confirm password" onChange={this.populateState.bind(this)}/>
            <p className="dialog-txt">By clicking here I Acknowledge that I&#39;m 18+ years old, have read, understend and accept the <a className="dialog-link" href="#">Terms and conditions</a></p>
          </div>
          <div className="dialog-footer">
            <div className="dialog-joinOptions">
              <button className="dialog-button button button-middle" onClick={this.register.bind(this)}>Join Now</button>
            </div>
            <p className="dialog-linkTxt">Already a member? <a className="dialog-link" onClick={this.props.openMainDialog.bind(this,'login')}>Log in here!</a></p>
          </div>
        </div>
      </Dialog>
    );
  }

}

function mapStateToProps(state){
  return {
    dialogs:state.getIn(['dialogs','main'])
  }
}

export {JoinDialog};
export const JoinDialogContainer = connect( mapStateToProps , Object.assign(dialogsActions,loginActions) )(JoinDialog);
