import ClientData from '../../utils/ClientData';

const dataMiddleware = store => next => action => {
  let result = next(action);
  if (action.manual) {
    let currentState = store.getState().toJS();
    currentState.updatedOn = new Date();
    ClientData.set('state', currentState);
  }
  return result;
}

export default dataMiddleware;
