import * as actions from '../CONSTANTS';

//ActionCreator
export default {
  sendChat:   (message) => {
    return (dispatch, getState) => {
      try {
        let roomName = getState().getIn(['chat', 'roomName']);
        dispatch({
          type: actions.WAITING_MESSAGE,
          emit:{
            event : actions.SEND_MESSAGE ,
            payload : { message: message, to: roomName }
          }
        });
      }
      catch(err) {
        console.log(err);
        dispatch({ type: actions.CANCEL_MESSAGE });
      }
    };
  },
  joinRoom:   (roomName) => {
    return {
      type: actions.WAITING_JOIN,
      roomName: roomName,
      emit:{
        event: actions.JOIN_ROOM,
        payload: roomName
      }
    };
  },
  switchRoom: (oldRoom, newRoom) => {
    return {
      type: actions.WAITING_JOIN,
      roomName: newRoom,
      emit:{
        event:actions.SWITCH_ROOM,
        payload:{_old: oldRoom, _new: newRoom}
      }
    };
  },
  echoMessage : () => {
    return { type : actions.ECHO_MESSAGE };
  },
  echoRoom : () => {
    return { type : actions.ECHO_ROOM };
  },
  receiveMessage : (message) => {
    return { type : actions.RECEIVE_MESSAGE , message : message };
  }
};
