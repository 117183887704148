const errorLogger = store => next => action => {
  const oldState = store.getState();
  try {
    return next(action);
  } catch (err) {
    console.error('Caught an exception!', err);
    let currentState = store.getState().toJS();
    console.log('Error State: ', currentState);
    console.log('Old State: ', oldState.toJS());
    if (action.type != 'UNDO') store.dispatch({type:'UNDO', oldState: oldState});
  }
}

export default errorLogger;
