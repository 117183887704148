/*eslint-disable no-unused-vars*/
import React from 'react';
import CloseChatScreen from './CloseChatScreen';
import ItemBox from './ItemBox';
/*eslint-enable no-unused-vars*/

class SideBar extends React.Component {
  shouldComponentUpdate(nextProps) {
    return nextProps.models != this.props.models;
  }

  onChangeLocation(newLocation) {
    this.props.onChangeLocation(newLocation, this.props.activeChat);
  }

  render() {
    let currentModels = this.props.models.valueSeq().toJS()
    return (
      <section className="sidebar-container">
        < CloseChatScreen onChangeLocation={this.onChangeLocation.bind(this)} />
        <ul className="itembox-list">
        {
          currentModels.map((model, index) => {
              return < ItemBox model={model} key={index} onChangeLocation={this.onChangeLocation.bind(this)} />
          })
        }
        </ul>
      </section>
    );
  }
}

SideBar.propTypes = {
  models: React.PropTypes.object.isRequired,
  performerName: React.PropTypes.string.isRequired,
  activeChat: React.PropTypes.bool.isRequired
}

export default SideBar;
