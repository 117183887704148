// Generated by CoffeeScript 1.8.0
(function() {
  var CookieStorage, LocalStorage, cookie, isLocalStorageAvailable;

  cookie = require('component-cookie');

  isLocalStorageAvailable = function() {
    var data, error, key;
    if (!window.localStorage) {
      return false;
    }
    try {
      key = 'test_key';
      data = Date.now() + Math.round(Math.random() * 1000) + '';
      window.localStorage.setItem(key, data);
      if (window.localStorage.getItem(key) !== data) {
        return false;
      }
      window.localStorage.removeItem(key);
    } catch (_error) {
      error = _error;
      return false;
    }
    return true;
  };

  CookieStorage = (function() {
    function CookieStorage() {}

    CookieStorage.prototype.set = function(key, value) {
      return cookie(key, value, {
        path: '/'
      });
    };

    CookieStorage.prototype.get = function(key) {
      return cookie(key);
    };

    CookieStorage.prototype.remove = function(key) {
      return cookie(key, null);
    };

    return CookieStorage;

  })();

  LocalStorage = (function() {
    function LocalStorage() {}

    LocalStorage.prototype.set = function(key, value) {
      return window.localStorage.setItem(key, value);
    };

    LocalStorage.prototype.get = function(key) {
      return window.localStorage.getItem(key);
    };

    LocalStorage.prototype.remove = function(key) {
      return window.localStorage.removeItem(key);
    };

    return LocalStorage;

  })();

  this.storageClass = isLocalStorageAvailable() ? LocalStorage : CookieStorage;

  module.exports = new this.storageClass();

}).call(this);
