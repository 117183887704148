/*
 JpgStreamer v1.0.7 (c)2014 Diginteligencia*/
function f(a, b, c) { null == g ? g = 0 : g++; this.a = b; this.c = c; this.index = g; this.i = []; this.r = -1; this.q = "load"; h(this, "G", "load"); this.ga = this.m = !1; this.A = 0; this.M = -1; this.L = null; this.X = 0; this.J = this.T = this.$ = this.ja = null; this.I = a.getContext("2d"); k(this) } var g; function k(a) { a.$ = setTimeout(function () { l(a) }, a.a.o - (new Date).getTime() % a.a.o) }
function l(a) {
	if (!a.m) {
		h(a, "F"); var b = a.q; switch (a.q) { case "load": a.i.length >= a.a.d * a.a.b && (b = "play"); break; case "play": 0 >= a.i.length && (b = "load", h(a, "g")) } b != a.q && (a.q = b, h(a, "G", b)); b = (new Date).getTime(); a.M = b; a.r = Math.round(b - a.a.N - 1E3 * a.a.p); 0 > a.r ? m(a) : a.r < a.a.B ? a.a.t = -1 : a.r < a.a.W ? a.a.t = 0 : a.r < a.a.P ? a.a.t = 1 : a.r < a.a.H ? a.a.t = 2 : m(a); 0 < a.A && n(a); if (0 <= a.a.t) {
			b = !1; if ("play" == a.q) { var c = a.i.shift(); null != c && (c.oa && (p(a, c), b = !0), q(a, c.time)) } if (r) {
				b || r && a.I.clearRect(0, 0, 60, 120); var c = 1E3 / a.c.n("j"), d =
				Math.pow(10, 1), c = a.q + "  " + a.a.d + "\n" + a.a.b + "  " + Math.floor(c * d) / d + "\n"; switch (a.q) { case "error": c += a.a.qa; break; case "load": case "play": c += Array(a.i.length + 1).join("|") + Array(a.a.U + 1).join(",") + Array(a.a.w + 1).join(".") + "\n", c += a.r + "\n", c += a.c.n("h") + "\n", c += a.c.n("i") + "\n" } s(c, a.I, "#ffffff", 11, 11); s(c, a.I, "#000000", 10, 10)
			} b && t(a.c, "i", (new Date).getTime() - a.M)
		} u(a)
	}
} function q(a, b) { a.a.p = v(a.a, a.a.p); var c = a.a; c.G == b && (c.G = null); c.F == b && (c.F = null); delete c.Q[b]; t(a.c, "k", a.i.length); h(a, "f", b) }
function u(a) { var b = (new Date).getTime(), c = a.L - b % a.L; 0 < a.M && (b = Math.ceil((b + c - a.M) / a.L) - 1, 0 < b && n(a, b)); a.$ = setTimeout(function () { l(a) }, c) } function n(a, b) { null != b && (a.A += b); if (0 < a.i.length && 0 < a.A && "play" == a.q) { var c = a.i.shift(); q(a, c.time); a.A-- } } function p(a, b) { a.L = b.o; try { a.I.drawImage(b.h, 0, 0) } catch (c) { } var d = (new Date).getTime(); 0 < a.X && t(a.c, "j", d - a.X); a.X = d } function m(a) { clearTimeout(a.$); a.m = !0; h(a, "e"); a.J = null; a.T = null } function h(a, b, c) { null != a.J && a.J.call(a.T, a, b, c) } f.prototype.toString = function () { }; function w(a, b, c, d, e) { this.c = e; this.va = a; this.na = null; this.time = b; this.oa = this.k = !1; this.aa = 0; this.s = d; this.Y = c; x(this) } function x(a) { a.h = new Image; a.h.l = a; a.h.onload = function () { this.l.k = !1; this.l.Z() }; a.h.onerror = function () { this.l.k = !1; this.l.D() }; a.na = (new Date).getTime(); setTimeout(function () { a.timeout() }, 2E3); a.k = !0; a.h.src = a.va + a.time } w.prototype.Z = function () { var a = (new Date).getTime() - this.na; this.oa = !0; t(this.c, "h", a); null != this.s && this.s.call(this.Y, this) };
w.prototype.timeout = function () { this.k && (delete this.h.onload, delete this.h.onerror, this.k = !1, this.h.removeAttribute("src"), this.D()) }; w.prototype.D = function () { 0 < this.aa-- ? setTimeout(x(this), 10) : null != this.s && this.s.call(this.Y, this) }; w.prototype.close = function () { null != this.h && (this.h.removeAttribute("src"), this.h.onload = null, this.h.onerror = null); null != this.s && this.s.call(this.Y, this); this.s = null }; var y = 1, z = 3; var JpgStreamer = function (a, b, c) { this.e = [1, 2, 5, 10]; if (1 > c) this.e = [c], this.ia = c; else { for (; this.e[this.e.length - 1] > c;) this.e.pop(); this.ia = this.e[this.e.length - 1] } this.m = !1; this.O = this.j = this.g = this.f = null; this.ea = document.getElementById(b); this.url = a; this.ra = this.url.substr(0, this.url.lastIndexOf("/") + 1); this.a = new A; B(this) }; JpgStreamer.prototype = {}; JpgStreamer.prototype.close = function () { this.m = !0; clearInterval(this.O); C(this) }; JpgStreamer.prototype.onStatus = function () { };
function B(a) { a.m || (C(a), clearInterval(a.O), a.O = setInterval(function () { D(new E(a.url, a, a.ka)) }, 3E4), D(new E(a.url, a, a.ka))) } function C(a) { null != a.f && (a.f.m || m(a.f), a.f = null); for (var b in a.g) a.g.hasOwnProperty(b) && a.g[b].close(); a.g = {}; a.j = {}; a.a.w = null; a.a.U = null; a.c = new F(a.a); a.a.P = 1E5; a.a.H = 1E5; r = !1; null == a.a.b && (a.a.b = a.ia); null == a.a.d && (a.a.d = 1); a.a.G = null; a.a.F = null }
JpgStreamer.prototype.ka = function (a) {
	if (200 == a.status) {
		var b = (new Date).getTime() - 1E3 * a.K; 0 < this.a.N && (t(this.c, "m", this.a.N - b), this.c.n("m")); this.a.N = b; if (null == this.f) { this.a.ba = parseFloat(a.K) - parseFloat(a.first); G(this); var c = H(Math.round(10 * (parseFloat(a.K) - this.a.B / 1E3)) / 10); this.a.C = c; this.a.p = c; c = this.f = new f(this.ea, this.a, this.c); c.J = this.ta; c.T = this; I(this) } else {
			a = this.ea; do { try { c = a.parentNode } catch (d) { } null != c && (a = c) } while (null != c); a != document && (this.m = !0, clearInterval(this.O), C(this));
			this.a.d > y ? 1 < this.c.V("k") && (this.a.d -= 1, G(this)) : (c = this.c.n("l"), a = 5E3 / this.c.n("h"), b = this.e[1 + this.e.indexOf(this.a.b)], null != b && a > b && 2.5 > c && (c = this.e.indexOf(this.a.b), c < this.e.length - 1 && (this.a.b = this.e[c + 1], G(this), this.a.d = y)))
		}
	}
};
JpgStreamer.prototype.ta = function (a, b, c) { switch (b) { case "e": this.m || (J(this), B(this)); break; case "F": 0 == this.f.i.length && 0 < K(this.j) && null != this.g && (a = this.g[this.a.p], null != a && a.close()); break; case "f": I(this); t(this.c, "l", this.a.w); break; case "g": this.a.d < z ? (this.a.d += 1, G(this)) : J(this); break; case "G": this.onStatus(c) } }; function J(a) { var b = a.e.indexOf(a.a.b); 0 < b && (a.a.b = a.e[b - 1], G(a), a.a.d = y) }
function G(a) { a.a.ma = 1 / a.a.b; a.a.o = 1E3 / a.a.b; y = 2 < a.a.b ? 1 : 2; z = 2 < a.a.b ? 3 : 5; if (1 > a.a.b) a.a.B = 1E3, a.a.W = 9E3, a.a.P = 9E3, a.a.H = 1E4; else { var b = Math.floor(a.a.b / 2); 0 >= b && (b = 1); a.a.B = 1E3 * a.a.d + a.a.o * b; a.a.W = a.a.B + 2 * a.a.o; a.a.P = 750 * a.a.ba; a.a.H = 1E3 * a.a.ba } } function I(a) { if (!a.m) { do { var b = a.f.i.length + K(a.j) + K(a.g); if (b = 5 > K(a.g) && b < a.a.d * a.a.b) { var c = new w(a.ra, a.a.C, a, a.ua, a.c); c.o = a.a.o; a.g[a.a.C] = c; a.a.C = v(a.a, a.a.C); a.a.w = K(a.g) } } while (b) } }
JpgStreamer.prototype.ua = function (a) { if (null != this.f) { var b = a.time; delete this.g[b]; this.a.w = K(this.g); if (!(b < this.a.p)) { this.j[b] = a; a = 20; do { var c = !1, d = this.f.ja; null == d && (d = this.a.p); b = this.j[d]; null != b && (delete this.j[d], c = this.f, c.ga || (p(c, b), c.ga = !0), c.i.push(b), c.ja = v(c.a, b.time), c = !0) } while (c && 0 < a--); for (var e in this.j) e < this.a.p && delete this.j[e] } this.a.U = K(this.j); I(this) } }; function E(a, b, c) { this.ca = c; this.da = b; this.K = this.first = null; this.status = -1; this.aa = 2; this.k = !1; this.url = a } function D(a) { a.u = new XMLHttpRequest; a.u.l = a; a.u.onreadystatechange = function () { 4 == this.readyState && (this.l.k = !1, this.l.status = this.status, 200 == this.status ? this.l.Z() : this.l.D()) }; setTimeout(function () { a.timeout() }, 2E3); a.k = !0; a.u.open("GET", a.url, !0); a.u.send() } E.prototype.Z = function () { var a = this.u.responseText.split(":"); "1.0" == a[0] && (this.first = a[1], this.K = a[2], this.ca.call(this.da, this)) };
E.prototype.timeout = function () { this.k && (this.u.abort(), this.u.onreadystatechange = null, this.D()) }; E.prototype.D = function () { 0 < this.aa-- ? D(this) : this.ca.call(this.da, this) }; function A() { this.H = this.P = this.W = this.B = this.N = this.ba = this.d = this.U = this.w = this.p = this.C = this.o = this.ma = this.b = null; this.qa = ""; this.debug = this.t = this.F = this.G = this.t = null; this.Q = {} } var r; function v(a, b) { if (null != a.Q[b]) return a.Q[b]; var c = L(a, b); switch (a.t) { case 1: null == a.G && (a.G = b, c = L(a, b, 1)); break; case 2: null == a.F && (c = L(a, b, a.d * a.b), a.F = c) } return a.Q[b] = c } function L(a, b, c) { b = parseFloat(b); b += a.ma; b = Math.round(10 * b) / 10; var ret = H(b); null != c && 0 < c && (ret = L(a, ret, c - 1)); return ret }; function F(a) { this.a = a; this.pa = 20; this.la = 100; this.ha = null; this.R = {} } function t(a, b, c) { a.a.b != a.ha && (a.la = a.pa * a.a.b, a.ha = a.a.b); var d = a.R[b]; null == d && (d = new M(a), a.R[b] = d); d.push(c) } F.prototype.n = function (a) { a = this.R[a]; if (null != a) return a.n() }; F.prototype.V = function (a) { a = this.R[a]; if (null != a) return a.V() }; function M(a) { this.sa = a; this.v = []; this.total = 0; this.S = null; this.fa = !0; this.max = this.min = null }
M.prototype.push = function (a) { this.v.push(a); for (this.total += a; this.v.length > this.sa.la;) this.total -= this.v.shift(); this.fa = !0 }; M.prototype.V = function () { return Array.min(this.v) }; M.prototype.n = function () { this.fa && 0 < this.v.length && (this.S = this.total / this.v.length); if (null != this.S) return this.S.toString().substr(0, 5) }; function s(a, b, c, d, e) { b.textAlign = "left"; b.textBaseline = "top"; b.fillStyle = c; c = []; a = a.replace(/\n\r?/g, "<br/>"); a = c = a.split("<br/>"); for (c = 0; c < a.length; c++) b.fillText(a[c], d, e), e += 20 } function H(a) { a == Math.floor(a) && (a += ".0"); return a } function K(a) { var b = 0, c; for (c in a) a.hasOwnProperty(c) && b++; return b } Array.max = function (a) { return Math.max.apply(Math, a) }; Array.min = function (a) { return Math.min.apply(Math, a) };

export default JpgStreamer;
