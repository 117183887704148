import React from 'react';
import SocketManager from '../../utils/SocketManager';
import * as actions from '../../redux/CONSTANTS';

class ChatSocket extends React.Component {

  constructor(props){
    super(props);
    this.chatListeners = false;
  }

  loadChatListeners(){
    if( this.chatListeners ){ return; }

    let Socket = SocketManager.getInstance();
    let component = this;

    Socket.on(actions.ECHO_MESSAGE, function() {
      component.props.echoMessage();
    });

    Socket.on(actions.ECHO_ROOM, function() {
      component.props.echoRoom();
    });

    Socket.on(actions.RECEIVE_MESSAGE, function(message) {
      component.props.receiveMessage(message);
    });

    this.chatListeners = true;
  }

  componentWillMount(){
    this.loadChatListeners.bind(this)();
  }

  render() {
    return (<i/>);
  }

}

ChatSocket.propTypes = {
  echoMessage: React.PropTypes.func.isRequired,
  echoRoom: React.PropTypes.func.isRequired,
  receiveMessage: React.PropTypes.func.isRequired
}

export default ChatSocket;
