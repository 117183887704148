import * as actions from '../CONSTANTS';

export default {
  openMainDialog: (dialogName)=>{
    return { type:actions.OPEN_MAIN_DIALOG , dialog : dialogName };
  },
  closeMainDialog: (dialogName)=>{
    return { type:actions.CLOSE_MAIN_DIALOG , dialog : dialogName };
  },
  openChatDialog: (dialogName)=>{
    return { type:actions.OPEN_CHAT_DIALOG , dialog : dialogName };
  },
  closeChatDialog: (dialogName)=>{
    return { type:actions.CLOSE_CHAT_DIALOG , dialog : dialogName };
  },
  openLocationDialog: (dialogName)=>{
    return { type:actions.OPEN_LOCATION_DIALOG , dialog : dialogName };
  },
  closeLocationDialog: (dialogName)=>{
    return { type:actions.CLOSE_LOCATION_DIALOG , dialog : dialogName };
  }
};
