/*eslint-disable no-unused-vars*/
import React from 'react';
/*eslint-enable no-unused-vars*/

class CloseChatScreen extends React.Component {

  changeLocation() {
    this.props.onChangeLocation('/');
  }

  render() {
    return (
      <div className="closeChatScreen">
        <a className="closeChatScreen-button" onClick={this.changeLocation.bind(this)}>
          Close
          <img src="../images/cross.png" width="8px" height="8px" />
        </a>
      </div>
    );
  }
}

export default CloseChatScreen;
