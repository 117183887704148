import {mainApi} from '../../../siteconfig.json';
import io from 'socket.io-client';
import Storage from 'key-storage';

class SocketManager {

  constructor(){
    this.instance=null;
  }

  getInstance( ){
    if( this.instance == null ){
      this.instance = io.connect(mainApi, {transports: ['websocket','polling'],query:"authorization="+Storage.get('authorization')});
    }
    return this.instance;
  }

  emit( event , payload ){
    this.getInstance().emit( event , payload );
  }

}

export default new SocketManager();
