module.exports={
  "mainApi": "http://ladybeard-test.bkrte.com:8080",
  "streamHost": "http://streamcdn.celclub.com",
  "originApp": "bangkok_origin",
  "ccbillPaymentForm": "https://sandbox-api.ccbill.com/wap-frontflex/flexforms/",
  "plans": [
    {
      "name": "Package 1",
      "credits": 17.99,
      "price":30.99,
      "bonus":0,
      "flexId": "90a4dbd1-101c-4e27-83f5-319a825ff6c9"
    },
    {
      "name": "Package 2",
      "credits": 27.99,
      "price":45.99,
      "bonus":1.99,
      "flexId": "957ee7db-7fb4-4727-a18a-d0386650a146"
    },
    {
      "name": "Package 3",
      "credits": 67.99,
      "price":102.99,
      "bonus":5.99,
      "flexId": "906ec659-49b3-4246-8cb4-5d968fd1e254"
    },
    {
      "name": "Package 4",
      "credits": 97.99,
      "price":149.99,
      "bonus":7.99,
      "flexId": "75a3cecf-885b-44d0-bd2e-f372e4a875b8"
    },
    {
      "name": "Package 5",
      "credits": 137.99,
      "price":199.99,
      "bonus":9.99,
      "flexId": "fb9bda3a-8b52-428b-b67a-fdd73021c6ed"
    }
  ],
  "initialState": {
      "chat": {
        "roomName": "",
        "connected": false,
        "waiting": false,
        "messageList": []
      },
      "user": {
        "logged": false,
        "username": "Guest"
      },
      "location": {
        "confirmChange": false,
        "newLocation": "",
        "oldLocation": ""
      },
      "models": {
        "isWaitingModels": false,
        "filters": [],
        "modelList": {

        }
      },
      "dialogs": {
        "main" : {
          "login":false,
          "join":false
        },
        "location" : {
          "confirm":false
        },
        "chat": {
          "confirm":false,
          "privateSignOn": false
        }
      },
      "login":{
        "isTryingToLogin":false,
        "isTryingToRegister":false
      }
  }
}
