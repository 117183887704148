import Home from '../components/Home/Home';
import { connect } from 'react-redux';
import { modelActions } from '../redux/actions';

function mapStateToProps(state){
  return {
    filters: state.getIn(['models', 'filters']),
    models: state.getIn(['models', 'modelList']),
    isWaitingModels: state.getIn(['models','isWaitingModels'])
  }
}

export default connect( mapStateToProps , modelActions )(Home);
