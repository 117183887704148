import { Map } from 'immutable';
import * as actions from '../CONSTANTS';

export default (state = Map(), action) => {
  switch (action.type) {
    case actions.LOGIN_WAIT:
      return state;
    case actions.LOGIN_SUCCESS:
      return state;
    case actions.LOGIN_WAIT:
      return state;
    case actions.REGISTER_SUCCESS:
      console.log(action);
      return state;
    case actions.REGISTER_FAILED:
      console.log(action);
      return state;
    case actions.REGISTER_WAIT:
      console.log(action);
      return state;
    case actions.LOGOUT_SUCCESS:
      return state;
    case actions.LOGIN_FAILED:
      return state;
    default:
      return state;
  }
}
