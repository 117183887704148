import * as VideoConstants from './videoConstants';
import siteconfig from '../../../../../siteconfig.json';

function getVideoSource(model, isJPGStreamer){
  let encodingProfile = model.IsOnline ?
    model.EncodingProfile : VideoConstants.EncodingProfiles.RETROFIT;

  let broadCast = `${encodingProfile}_${model.StageName}`;
  let streamHost = siteconfig.streamHost;
  let originApp = siteconfig.originApp;

  if(isJPGStreamer)
    return `${streamHost}/jpgstream/${broadCast}/m`;

  let resolutionProfile = getResolutionProfile();
  let deliveryProfile = getDeliveryProfile(encodingProfile, resolutionProfile);

  return `${streamHost}/${originApp}/${broadCast}/${deliveryProfile}.smil/playlist.m3u8`;
}

function getScreenResolution(){
  let width = window.screen.availWidth || window.screen.width,
      height = window.screen.availHeight || window.screen.height;

  if(height > width){
    let temp = width;
    width = height;
    height = temp;
  }

  return { width: width, height: height};
}

function getResolutionProfile(){
  let resolution = getScreenResolution();
  let height = resolution.height;

  let resolutionProfile = VideoConstants.ResolutionProfiles.Pro240p;

  if(height > 480)
    resolutionProfile = VideoConstants.ResolutionProfiles.Pro720p;
  else if(height > 360)
    resolutionProfile = VideoConstants.ResolutionProfiles.Pro480p;
  else if(height > 240)
    resolutionProfile = VideoConstants.ResolutionProfiles.Pro360p;

  return resolutionProfile;
}

function getDeliveryProfile(encodingProfile, resolutionProfile){
  let exceptions = VideoConstants.DeliveryProfileExceptions;

  for (var x = 0; x < exceptions.length; x++) {
			if (encodingProfile === exceptions[x].profile) {
				if (exceptions[x].notSupportedRes.indexOf(resolutionProfile) > -1) {
					resolutionProfile = exceptions[x].minimumSupported;
					break;
				}
			}
		}

    return VideoConstants.DeliveryProfiles[resolutionProfile];
}

export default getVideoSource;
