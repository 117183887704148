import * as actions from '../CONSTANTS';
import { Map } from 'immutable';

export default (state = Map(), action) => {
  let newState = state;
  switch (action.type) {
    case actions.CHANGE_LOCATION:
      return state.updateIn( ['confirmChange'], value => true);
    case actions.CONFIRM_CHANGE:
      return state.updateIn( ['confirmChange'], value => false);
    default:
      return state;
  }
  return newState;
}
