import * as actions from '../CONSTANTS';
import API from '../../utils/API';
import Storage from 'key-storage';

export default {
  loginAttempt:( infoObject )=>{
    return ( dispatch , getState )=>{
      if( getState().getIn(['login','isTryingToLogin']) ) return null;
      API.loginAttempt(infoObject).then((response)=>{
        if( response.data.jwt ){
          Storage.set( 'authorization' , response.data.jwt );
        }
        dispatch( { type:actions.LOGIN_SUCCESS , token:response.data.token } );
      }).catch((error)=>{
        Storage.remove( 'authorization' );
        dispatch( { type:actions.LOGIN_FAILED , response:error } );
      });
      dispatch( { type:actions.LOGIN_WAIT } )
    }
  },
  logoutAttempt:()=>{
    return (dispatch,getState)=>{
      Storage.remove( 'authorization' );
      dispatch( { type:actions.LOGOUT_SUCCESS } );
    }
  },
  registerAttempt(infoObject){
    return ( dispatch , getState )=>{
      if( getState().getIn(['login','isTryingToRegister']) ) return null;
      if( infoObject.Password !== infoObject.Cpassword ){
        dispatch( { type:actions.REGISTER_FAILED , response:'Passwords don&#39#;t match.' } );
        return;
      }
      API.registerAttempt(infoObject).then((response)=>{
        if( response.data.jwt ){
          Storage.set( 'authorization' , response.data.jwt );
        }
        dispatch( { type:actions.REGISTER_SUCCESS , token:response.data.token } );
      }).catch((error)=>{
        dispatch( { type:actions.REGISTER_FAILED , response:error } );
      });
      dispatch( { type:actions.REGISTER_WAIT } )
    }
  }
};
