import { createStore, applyMiddleware } from 'redux';
import reducer from '../redux/reducers/index';
import logger from '../redux/middleware/logger';
import dataMiddleware from '../redux/middleware/dataMiddleware';
import error from '../redux/middleware/error';
import socketMiddleware from '../redux/middleware/socketMiddleware';
import thunk from 'redux-thunk';

import stateLoader from '../utils/stateLoader';

const createStoreWithMiddleware = applyMiddleware(thunk, dataMiddleware, socketMiddleware , logger, error)(createStore);
const store = createStoreWithMiddleware(reducer, stateLoader());

//Required for replaying actions from devtools to work
//reduxRouterMiddleware.listenForReplays(store)

export default store;
