import React from 'react';
/*eslint-disable no-unused-vars*/
import ChatBox from './ChatBox';
/*eslint-disable no-unused-vars*/
import {Link as LinkS, Events } from 'react-scroll';
import { Link } from 'react-router';

class ChatBar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {isOpenChat: true};
  }

  openChat(){ this.setState({isOpenChat: true}); }
  closeChat(){ this.setState({isOpenChat: false}); }

  componentDidMount(){
    Events.scrollEvent.register('begin');
    Events.scrollEvent.register('end');
  }

  componentWillUnmount(){
    Events.scrollEvent.remove('begin');
    Events.scrollEvent.remove('end');
  }

  render() {
    let isOpenChat = this.state.isOpenChat;
    return (
      <section className="chatbar-container">
        <section className="chatbar-list">

          <LinkS className="chatbar-mybio" activeClass="active" to="modelInfo" spy={true} smooth={true} offset={50} duration={500} >
            <span>Check my Bio</span>
          </LinkS>

          <a className="chatbar-private" onClick={this.props.openChatDialog.bind(this, 'privateSignOn')}>
            <span>Start Private Show</span>
          </a>
          <Link to="/payments" className="chatbar-buy" >
            <span>Buy Credits</span>
          </Link>
          <div className="chatbar-chat">
            <a className="chatbar-chat-open" onClick={this.openChat.bind(this)}>
              <span>{this.props.model.get('StageName')}'s Chat</span>
              <img src="../images/up_arrow.png" width="15px" height="15px" align="right" />
            </a>
            < ChatBox open={isOpenChat}
                      closeChat={this.closeChat.bind(this)}
                      model={this.props.model}
                      waiting={this.props.waiting} sendChat={this.props.sendChat}
                      />
          </div>
        </section>
      </section>
    );
  }
}

ChatBar.propTypes = {
  model: React.PropTypes.object.isRequired,
  openChatDialog: React.PropTypes.func.isRequired,
  closeChatDialog: React.PropTypes.func.isRequired
}

export default ChatBar;
