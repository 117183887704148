class ClientData {
  constructor(){
    this.localStorageEnabled = !!window.localStorage;
    this.cookiesEnabled = navigator.cookieEnabled;
  }
  _cookie(key){
    let value = "; " + document.cookie;
    let parts = value.split("; " + key + "=");
    if (parts.length == 2){
      return parts.pop().split(";").shift();
    }else{
      return null;
    }
  }
  set(key,value){
    if( !(key.constructor === String || key.constructor === Number) ){
      throw new Error('The key must be either a String or a Number');
    }
    if(this.localStorageEnabled){
      window.localStorage.setItem( key , ( value.constructor === Object ) ? JSON.stringify( value ) : value );
    }
    if(this.cookiesEnabled){
      document.cookie =  key +"="+ ( value.constructor === Object ) ? JSON.stringify( value ) : value ;
    }
  }
  unset(key){
    window.localStorage.removeItem(key);
    document.cookie=key+'=;Max-Age=0';
  }
  get(key){
    let value;
    if( this.localStorageEnabled ){
      value = window.localStorage.getItem( key );
    }
    if( !value && this.cookiesEnabled ){
      value = this._cookie(key);
    }
    if(value){
      try{
        return JSON.parse( value ); // To support object storage
      }catch(e){
        return value;
      }
    }
    return null;
  }
}

export default new ClientData();
