/*eslint-disable no-unused-vars*/
import Dialog from './Dialog';
/*eslint-enable no-unused-vars*/
import React from 'react';
import { connect } from 'react-redux';
import dialogsActions from '../../redux/actions/dialogsActions';

class ConfirmDialog extends React.Component{

  changeLocation() {
    let newLocation  = this.props.getToLocation();
    this.props.onChangeLocation(newLocation, false);
  }

  render(){
    let stageName = this.props.stageName;
    return (
      <Dialog isOpen={this.props.dialogs.getIn(['confirm'])} transitionName="dialog-anim">
        <div className="confirmDialog">
          <div className="confirmDialog-header">
            <h2 className="confirmDialog-title">Are you sure to continue?</h2>
            <a className="confirmDialog-close" onClick={this.props.closeLocationDialog.bind(this,'confirm')}>X</a>
          </div>
          <div className="confirmDialog-body">
            <div className="confirmDialog-body-container">
              <p className="confirmDialog-text">You are chating with {stageName}, do you want to go out?</p>
            </div>
            <div className="confirmDialog-body-container button-group">
              <a className="confirmDialog-button button button-middle button-warn" onClick={this.changeLocation.bind(this)}>Yes</a>
              <a className="confirmDialog-button button button-middle" onClick={this.props.closeLocationDialog.bind(this,'confirm')}>No</a>
            </div>
          </div>
        </div>
      </Dialog>
    );
  }

}

function mapStateToProps(state){
  return {
    dialogs: state.getIn(['dialogs','location'])
  }
}

export {ConfirmDialog};
export const ConfirmDialogContainer = connect( mapStateToProps , dialogsActions )(ConfirmDialog);
