import React from 'react';
import JPGPlayer from './modules/JPGStreamer'
import * as VideoConstants from './common/videoConstants';

let jpgStreamerObject = undefined;

class JPGStreamer extends React.Component {

  shouldComponentUpdate(){
    return false;
  }

  setupVideo(props) {
    if(typeof jpgStreamerObject !== 'undefined'){
      jpgStreamerObject.close();
    }
    try {
      jpgStreamerObject = new JPGPlayer(props.videoSource, "jpgstreamer", 10);
      jpgStreamerObject.onStatus = (status) => {
          if(status === "play"){
            props.callBacks.videoPlaying();
          }
        }
    }catch(ex){
      props.callBacks.videoFailed(VideoConstants.JPGStreamer, ex);
    }
  }

  componentDidMount(){
    this.setupVideo(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.setupVideo(nextProps);
  }

  componentWillUnmount(){
    if(typeof jpgStreamerObject !== 'undefined'){
      jpgStreamerObject.close();
    }
  }

  render(){
    let posterUrl = "";
    if (this.props.posterUrl) {
      posterUrl = this.props.posterUrl;
    }
    let divStyle = {
      backgroundImage: 'url(' + posterUrl + ')',
    }
    return <canvas style={divStyle} className="jpgstreamer-canvas" id="jpgstreamer"> </canvas>
  }
}

JPGStreamer.propTypes = {
  callBacks: React.PropTypes.object.isRequired,
  videoSource: React.PropTypes.string.isRequired
}

export default JPGStreamer;

/*NOTE: remember that global variables like 'JPGStreamer ='
were replaced to 'var JPGStreamer =' in order to make it work as module*/
